'use strict';

import { debounce } from './../../_scripts/helpers/listeners.js';

const $navToggle = document.querySelector('.js-toggle-navigation'),
  $headerNavWrap = document.querySelector('.header__nav-wrap');

const Header = {
  init: function (){
    let _self = this;

    if($navToggle){
      $navToggle.addEventListener('click', function(){
        _self.toggleNavigation(this);
      });
    }
  },

  toggleNavigation: function($btn) {
    let ariaExpanded = $btn.getAttribute('aria-expanded') === 'true' ? false : true;

    $btn.setAttribute('aria-expanded', ariaExpanded);
    $btn.querySelector('svg').classList.toggle('active');
    $headerNavWrap.classList.toggle('open');

    if(ariaExpanded) {
      document.querySelector('.header__nav').focus();
    }
  }
};

export default Header;
