// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import Header from '../_modules/header/header';
import SkipToMain from './../_modules/skip-to-main/skip-to-main';

var Accordion = require('../_modules/strategy/strategy');


//Init module
Header.init();
SkipToMain.init();

$(() => {
	// new Link(); // Acivate Link modules logic
	new Accordion(); // Acivate Link modules logic
	// console.log('Welcome to Yeogurt!');
});




