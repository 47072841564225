'use strict';

const SkipToMain = {
  init: function() {
    const $link = document.querySelector('.js-skip-to-main');

    $link.addEventListener('click', function(event){
      event.preventDefault();

      const mainId = this.dataset.linkTo,
        section = document.getElementById(mainId);

      section.setAttribute('tabindex', '-1');

      section.addEventListener('blur', function(){
        this.removeAttribute('tabindex');
      }); 

      section.focus();
    });
  }
};

export default SkipToMain;
