'use strict';
import $ from 'jquery';

export default class Accordion  {
  constructor() {
    this.name = 'Accordion';
		// console.log('%s module', this.name.toLowerCase());

		var $parent = $('.content-strategy .accordion-title span');
		var $body_text = $('.accordion-content');

		//#region accordion click event
	  $parent.click(function (e) {
	    e.preventDefault();

			if ($(this).parent('.accordion-title').hasClass('-active')) {
				return false;
			} else {
				$parent.parent('.accordion-title').removeClass('-active');
				$(this).parent('.accordion-title').toggleClass('-active');

				if ($(this).parent('.accordion-title').hasClass('-purpose')) {
					$body_text.removeClass('-is-expanded');
					$('.accordion-content.-purpose').addClass('-is-expanded');

				}	else if ($(this).parent('.accordion-title').hasClass('-goals')){
					$body_text.removeClass('-is-expanded');
					$('.accordion-content.-goals').addClass('-is-expanded');
				} else if ($(this).parent('.accordion-title').hasClass('-strategies')) {
					$body_text.removeClass('-is-expanded');
					$('.accordion-content.-strategies').addClass('-is-expanded');
				} else {
					return false;
				}
			}
	  });
	  //#endregion

		// #region - modal -
		var $modal = $('#imgModal');		
		var $img = $('#strategyImage');
		var $modalImg = $('#strategyImage_popUp');
		var $span = $(".close");
		
		$img.click(function(){			
			$modal.css('display', 'block');
			$modalImg.attr('src', this.src);			
		});
		
		$span.click(function() {
			$modal.css('display', 'none');
		});
		// #endregion -	
  }
}
